import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './footer.css';

import qr from '../assests/qr.jpg'

const Bankfooter = () => {
    const [loading, setLoading] = useState(true);
    const [bankData, setBankData] = useState([]);
    console.log("bankData", bankData);
    const fetchBank = async () => {
        try {
            const response = await fetch("https://api.srisrinivasacrackers.com/bank/getallbank", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseDataBank", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setBankData(responseData.data);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchBank();
    }, []);
    return (
        <footer style={{ backgroundColor: '#1c1c1c', color: '#ffffff' }} className='mt-lg-5 my-1'>
            <div className="container-fluid">
                <Row>
                    {bankData.map((bank, index) => (
                        <Col lg={3} md={3} sm={6} xs={12} className='small box d-flex flex-column align-items-center justify-content-center p-lg-4 p-2' key={index}>
                            <h5 className="mb-md-3 mb-1 text-md-start text-center">{bank.acc_name}</h5>
                            <p className="text-center">{bank.bank_name}</p>
                            <p className="text-center">Ac No: {bank.acc_no}</p>
                            <p className="text-center">IFSC: {bank.ifsc_code}</p>
                            <p className="text-center">Branch: {bank.branch}</p>
                            {bank.qr_code_url && (
                                <img src={`https://api.srisrinivasacrackers.com/uploads/images/${bank.qr_code_url}`} alt="QR Code" className="qr-code-img" style={{ maxWidth: '150px', marginTop: '10px' }} />

                            )}
                        </Col>
                    ))}
                </Row>
            </div>
        </footer>
    );
};

export default Bankfooter;
