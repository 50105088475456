import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import logo from '../assests/srisrilogo.png'
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
const Navbars = () => {
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [contactData, setContactData] = useState([]);
    console.log("contactData", contactData);
    const fetchContact = async () => {
        try {
            const response = await fetch("https://api.srisrinivasacrackers.com/company/company-1726470475704-5505/getcompanybyid", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseDataContact", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setContactData(responseData.company);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchContact();
    }, []);
    const handleNavLinkClick = () => {
        setExpanded(false); // Close the navbar after a link is clicked
    };
    return (
        <div>
            <div className='whatsapp-icon'>
                <a href={`https://api.whatsapp.com/send?phone=+91${contactData.mobile_number_1}`} target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp style={{ fontSize: "35px ", color: "#FFFFFF" }} />
                </a>
            </div>
            <Navbar expand="lg" bg="white" variant="light" className="px-3 navbars" expanded={expanded} >

                <div className='d-flex align-items-center '>
                    <div className=''>
                        <div className="site-logo">
                            <a href="https://www.srisrinivasacrackers.com/" className="wd-logo wd-main-logo" rel="home">
                                <img
                                    src={logo}
                                    width="50"
                                    height="50"
                                    alt="Sri Srinivasa Crackers"
                                    style={{ maxWidth: '263px' }}
                                />
                            </a>
                        </div>
                    </div>
                    <div className=''>
                        <div className="wd-header-text set-cont-mb-s reset-last-child">
                            <h5>

                                <span style={{ color: '#d12b1e' }} className='navbar-name'>SRI SRINIVASA CRACKERS</span>

                            </h5>
                        </div>
                    </div>
                </div>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto d-md-flex flex-column flex-lg-row align-items-center navslink-button">
                        <Nav.Link as={Link} to={"/retailprice"} onClick={handleNavLinkClick} className="btn btn-pink mx-2 nav-button-width b" style={{ width: "130px" }}>RETAIL PRICE</Nav.Link><span className="py-1"></span>
                        <Nav.Link as={Link} to={"/familypack"} onClick={handleNavLinkClick} className="btn btn-pink mx-2 nav-button-width" style={{ width: "130px" }}>COMBO PACK</Nav.Link><span className="py-1"></span>
                        <Nav.Link as={Link} to={"/wholesale"} onClick={handleNavLinkClick} className="btn btn-pink mx-2 nav-button-width" style={{ width: "130px" }}> WHOLESALE</Nav.Link><span className="py-1"></span>
                        <Nav.Link as={Link} to={"/giftbox"} onClick={handleNavLinkClick} className="btn btn-pink mx-2 nav-button-width" style={{ width: "130px" }}>GIFT BOX</Nav.Link><span className="py-1"></span>
                        <Nav.Link as={Link} to={"/about"} onClick={handleNavLinkClick} className="btn btn-pink mx-2 nav-button-width" style={{ width: "130px" }}>ABOUT US</Nav.Link><span className="py-1"></span>
                        <Nav.Link as={Link} to={'/contact'} onClick={handleNavLinkClick} className="btn btn-pink mx-2 nav-button-width" style={{ width: "130px" }}>CONTACT US</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Navbars
