import React, { useState, useEffect } from 'react';
import './about.css'
import CountUp from 'react-countup';
import { Navbar, Nav, Container, Button, Row, Col } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa6";
import SlickFooter from '../pages/SlickFooter'
import Footer from './Footer';
import { FaWhatsapp } from 'react-icons/fa';
import { CiLocationOn, CiMail, CiPhone } from 'react-icons/ci';

const Contact = () => {
    const [loading, setLoading] = useState(true);
    const [contactData, setContactData] = useState([]);
    console.log("contactData", contactData);
    const fetchContact = async () => {
        try {
            const response = await fetch("https://api.srisrinivasacrackers.com/company/company-1726470475704-5505/getcompanybyid", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseDataContact", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setContactData(responseData.company);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchContact();
    }, []);
    return (
        <div>

            <div>
                <div className='about-img py-md-5 py-5'>
                    <div className="about-img-banner-content py-md-5 py-0">
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>
            <div className='pt-md-5 pt-2'>
                <Container className="my-md-5">
                    <Row className="text-center" style={{ display: "flex", alignItems: "stretch" }}>
                        <Col lg={4} md={6} className="d-flex">
                            <div className="py-4 px-3 border shadow-sm rounded flex-fill mb-2">
                                {/* <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" className="text-danger mb-3" /> */}
                                <h5 className='d-flex align-items-center justify-content-center'><CiLocationOn className='text-danger' /> &nbsp;Address</h5>
                                <p className='' style={{ color: "gray" }}>
                                    {contactData.address}
                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} className="d-flex">
                            <div className="py-4 px-3 border shadow-sm rounded flex-fill mb-2">
                                {/* <FontAwesomeIcon icon={faPhoneAlt} size="2x" className="text-danger mb-3" /> */}
                                <h5 className='d-flex align-items-center justify-content-center'><CiPhone className='text-danger' /> &nbsp;Phone</h5>
                                <p><a style={{ color: 'gray' }} className='text-decoration-underline' href={`Phone No:${contactData.mobile_number_1}`}>+91{contactData.mobile_number_1}</a></p>
                                <p><a style={{ color: 'gray' }} className='text-decoration-underline' href={`Phone No:${contactData.mobile_number_2}`}>+91{contactData.mobile_number_2}</a></p>
                            </div>
                        </Col>
                        <Col lg={4} md={6} className="d-flex">
                            <div className="py-4 px-3 border shadow-sm rounded flex-fill mb-2">
                                {/* <FontAwesomeIcon icon={faEnvelope} size="2x" className="text-danger mb-3" /> */}
                                <h5 className='d-flex align-items-center justify-content-center'><CiMail className='text-danger' /> &nbsp;Support</h5>
                                <p><a style={{ color: 'gray' }} className='text-decoration-underline' href={`mailto:${contactData.email_1}`}>{contactData.email_1}</a></p>
                                <h5 className='d-flex align-items-center justify-content-center'><CiMail className='text-danger' /> &nbsp;Sales</h5>
                                <p><a style={{ color: 'gray' }} className='text-decoration-underline' href={`mailto:${contactData.email_2}`}>{contactData.email_2}</a></p>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
            {/* <div className='p-3 mt-4'>
                <Container>
                    <SlickFooter />
                </Container>
            </div> */}
            <div className="container-fluid border">
                <div className="row">
                    <div className="col-12 col-xl-12 col-xxl-12">
                        <iframe
                            loading="lazy"
                            src="https://maps.google.com/maps?q=Sri%20Srinivasaa%20Crackers%2C%20Sivakasi%2C%20main%20road%2C%20Kumaralingapuram%2C%20Tamil%20Nadu%20626005&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                            title="Sri Srinivasaa Crackers, Sivakasi, main road, Kumaralingapuram, Tamil Nadu 626005"
                            aria-label="Sri Srinivasaa Crackers, Sivakasi, main road, Kumaralingapuram, Tamil Nadu 626005"
                            style={{ border: 0, width: "100%", height: "450px" }}
                        ></iframe>
                    </div>
                </div>
            </div>

            <div>
                <Footer />
            </div>
        </div>
    )
}

export default Contact