import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Button, Row, Col, Image } from 'react-bootstrap';
import './footer.css';
import logo from '../assests/srisrilogo.png'
import { IoMdMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa6";
import { IoLocation } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { RiTwitterXFill } from "react-icons/ri";
const Footer = () => {
    const [loading, setLoading] = useState(true);
    const [contactData, setContactData] = useState([]);
    console.log("contactData", contactData);
    const fetchContact = async () => {
        try {
            const response = await fetch("https://api.srisrinivasacrackers.com/company/company-1726470475704-5505/getcompanybyid", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            console.log("responseDataContact", responseData);
            setLoading(false);
            if (responseData.status === 200) {
                setContactData(responseData.company);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchContact();
    }, []);
    return (
        <div className=''>
            <div className='fotter'>
                <Container fluid className='p-4'>
                    <Row>
                        <Col lg={4} md={12} sm={12} className='py-3'>
                            <div className='d-flex align-items-center'>
                                <div className='fotterlogo'>
                                    <Image
                                        src={logo}
                                        roundedCircle
                                        fluid
                                    />
                                </div>
                                <div>
                                    <h5 className="footer-comapny-name" style={{ color: "#fbf206", fontSize: "26px", lineHeight: "1.3em", fontFamily: "sans-serif", marginTop: "-1px" }} >Sri Srinivasa Crackers</h5>
                                </div>
                            </div>
                            <div className='text-white mt-3'>
                                <p>
                                    <span style={{ fontWeight: "400", fontSize: "18px" }}>Sri Srinivasa Crackers is a top-tier Manufacturer , Wholesale and Retail supplier of exceptional quality fireworks, providing its products to customers across various regions.</span>
                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} className='py-3 pt-5'>
                            <div>
                                <h5 className="footer-comapny-names" style={{ color: "#fbf206", fontSize: "26px", lineHeight: "1.3em", fontFamily: "sans-serif" }}>Contact us</h5>
                                <div className='d-flex align-items-center py-2'>
                                    <div className='fottericon'>
                                        <IoLocation style={{ fontSize: "25px", color: "#FFFFFF" }} />
                                    </div>
                                    <div className='mx-3'>
                                        <p className='fotterpara' style={{ color: "#FFFFFF" }}>{contactData.address}</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center py-2'>
                                    <a href={`tel:+${contactData.mobile_number_1}`} className='d-flex align-items-center' style={{ textDecoration: 'none' }}>
                                        <div className='fottericon'>
                                            <FaPhone style={{ fontSize: "22px", color: "#FFFFFF" }} />
                                        </div>
                                        <div className='mx-4 mt-1'>
                                            <p className='fotterpara fotterpara-underline' style={{ color: "#FFFFFF" }}>{contactData.mobile_number_1}</p>
                                        </div>
                                    </a>
                                </div>

                                <div className='d-flex align-items-center py-2'>
                                    <a href={`tel:+${contactData.mobile_number_2}`} className='d-flex align-items-center' style={{ textDecoration: 'none' }}>
                                        <div className='fottericon'>
                                            <FaPhone style={{ fontSize: "22px", color: "#FFFFFF" }} />
                                        </div>
                                        <div className='mx-4 mt-1'>
                                            <p className='fotterpara fotterpara-underline' style={{ color: "#FFFFFF" }}>{contactData.mobile_number_2}</p>
                                        </div>
                                    </a>
                                </div>
                                <div className='d-flex align-items-center py-2'>
                                    <a href={`mailto:${contactData.email_1}`} className='d-flex align-items-center' style={{ textDecoration: 'none' }}>
                                        <div className='fottericon'>
                                            <IoMdMail style={{ fontSize: "25px", color: "#FFFFFF" }} />
                                        </div>
                                        <div className='mx-4 mt-1'>
                                            <p className='fotterpara fotterpara-underline' style={{ color: "#FFFFFF" }}>{contactData.email_1}</p>
                                        </div>
                                    </a>
                                </div>
                                <div className='d-flex align-items-center py-2'>
                                    <a href={`mailto:${contactData.email_2}`} className='d-flex align-items-center' style={{ textDecoration: 'none' }}>
                                        <div className='fottericon'>
                                            <IoMdMail style={{ fontSize: "25px", color: "#FFFFFF" }} />
                                        </div>
                                        <div className='mx-4 mt-1'>
                                            <p className='fotterpara fotterpara-underline' style={{ color: "#FFFFFF" }}>{contactData.email_2}</p>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </Col>
                        <Col lg={4} md={12} sm={12} className='pt-5'>
                            <div className=''>
                                <h2 className="footer-comapny-names mb-3" style={{ color: "#fbf206", fontSize: "26px", lineHeight: "1.3em", fontFamily: "sans-serif" }}>Social icons</h2>
                                <a href="https://www.facebook.com/damaaldumeelvedi?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className="social-icon">
                                    <FaFacebook />
                                </a>
                                <a href="https://www.youtube.com/@srisrinivasacrackers" target="_blank" rel="noopener noreferrer" className="social-icon" style={{ backgroundColor: "#FF0000" }}>
                                    <FaYoutube />
                                </a>
                                <a href={`https://api.whatsapp.com/send?phone=+91${contactData.mobile_number_1}`} target="_blank" rel="noopener noreferrer" className="social-icon" style={{ backgroundColor: "#25D366" }}>
                                    <FaWhatsapp />
                                </a>

                                <a href="https://instagram.com/sri_srinivasa_crackers?igshid=MmIzYWVlNDQ5Yg==" target="_blank" rel="noopener noreferrer" className="social-icon" style={{ backgroundColor: "#FFFFFF" }}>
                                    <FaInstagram style={{ color: "#E4405F" }} />
                                </a>

                                <a href="https://twitter.com/YourProfileName" target="_blank" rel="noopener noreferrer" className="social-icon" style={{ backgroundColor: "#1DA1F2" }}>
                                    <RiTwitterXFill />
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <div className='fotterlast p-2'>
                    <h5 className='text-center small'>Copyright @ 2024 , Sri Srinivasa Crackers Developed By Barbikan Technologies.All rights reserved</h5>
                </div>
            </div>
        </div >
    )
}

export default Footer