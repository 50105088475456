import React, { useState, useEffect } from "react";
import { Modal, Button, Badge } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import { FaShoppingCart, FaTrash } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fireworks from '@fireworks-js/react';
const WholesaleProduct = () => {
    const [retailData, setRetailData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [quantities, setQuantities] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);
    const [showFireworks, setShowFireworks] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
        email: "",
        address: "",
        state: "",
        deliveryCity: ""
    });
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isCheckoutDisabled, setIsCheckoutDisabled] = useState(false);
    const isFormValid = () => {
        return (
            formData.name.trim() !== "" &&
            formData.phoneNumber.trim() !== "" &&
            // formData.email.trim() !== "" &&
            formData.address.trim() !== "" &&
            formData.state.trim() !== "" &&
            formData.deliveryCity.trim() !== ""
        );
    };
    const fetchDataRetail = async () => {
        try {
            const response = await fetch("https://api.srisrinivasacrackers.com/split/subcategory?q=WholeSale Case", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            setLoading(false);

            if (responseData.status === 200) {
                if (responseData.result[0] && responseData.result[0].SubCategories) {
                    setRetailData(responseData.result[0].SubCategories);
                } else {
                    console.error("SubCategories data is missing.");
                }
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error.message);
        }
    };

    useEffect(() => {
        fetchDataRetail();
    }, []);

    const fetchCustomerOrder = async () => {
        try {
            const orderProducts = filteredProducts.map(({ key, product, quantity }) => {
                const [subcategoryIndexStr, productIndexStr] = key.split("-");
                const subcategoryIndex = parseInt(subcategoryIndexStr, 10);
                const productIndex = parseInt(productIndexStr, 10);

                // Validate indices
                if (isNaN(subcategoryIndex) || isNaN(productIndex)) {
                    throw new Error("Invalid subcategory or product index.");
                }

                const subcategory = retailData[subcategoryIndex];
                if (!subcategory) {
                    throw new Error(`Subcategory at index ${subcategoryIndex} not found.`);
                }

                const discount = subcategory.subcategory_discount || 0;
                const discountPrice = product.product_price - (product.product_price * discount) / 100;

                if (isNaN(discountPrice)) {
                    throw new Error(`Invalid discount price for product ${product.product_id}.`);
                }

                const productTotal = calculateProductTotal(subcategoryIndex, productIndex, discountPrice);

                return {
                    product_id: product.product_id,
                    product_name: product.product_name,
                    product_code: product.product_code,
                    product_price: product.product_price,
                    product_content: product.product_content,
                    image_url: product.image_url,
                    video_url: product.video_url,
                    active: product.active,
                    quantity: quantity,
                    discountPrice: discountPrice,
                    productTotal: productTotal
                };
            });

            const response = await fetch(
                "https://api.srisrinivasacrackers.com/order/createorder",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        new_order: 0,
                        customer_name: formData.name,
                        phone_number: formData.phoneNumber,
                        state: formData.state,
                        city: formData.deliveryCity,
                        address: formData.address,
                        email: formData.email,
                        actual_price: totalActualPrice,
                        discount_price: totalOfferPrice,
                        total_price: totalDiscountValue,
                        order_products: orderProducts,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }

            const responseData = await response.json();
            if (responseData.status === 200) {
                console.log("Order placed successfully:", responseData);
                // Optionally reset form and quantities here
                setShowModal(false);
                setQuantities({});
                setFormData({
                    name: "",
                    phoneNumber: "",
                    email: "",
                    address: "",
                    state: "",
                    deliveryCity: ""
                });

                toast.success('Your Order placed successfully!', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",

                });
                setShowFireworks(true); // Show fireworks
                setTimeout(() => {
                    setShowFireworks(false); // Hide fireworks after 5 seconds
                }, 8000);
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            console.error("Error placing order:", error.message);
            toast.error("Error placing order. Please try again.");
            // Optionally display error to user
        }
    };

    const handleFormDataChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    useEffect(() => {
        setIsCheckoutDisabled(!isFormValid());
    }, [formData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            setIsCheckoutDisabled(true);
            fetchCustomerOrder();
        } else {
            // Optionally show a validation error to the user
        }
    };

    const handleQuantityChange = (subcategoryIndex, productIndex, value) => {
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [`${subcategoryIndex}-${productIndex}`]: value,
        }));
    };

    const handleDeleteProduct = (key) => {
        setQuantities((prevQuantities) => {
            const newQuantities = { ...prevQuantities };
            delete newQuantities[key];
            return newQuantities;
        });
    };

    const calculateProductTotal = (subcategoryIndex, productIndex, discountPrice) => {
        const quantity = quantities[`${subcategoryIndex}-${productIndex}`];
        const validQuantity = Number.isInteger(quantity) && quantity > 0 ? quantity : 0;
        const total = discountPrice * validQuantity;
        return isNaN(total) ? "0.00" : total.toFixed(2);
    };

    const calculateTotalActualPrice = () => {
        let total = 0;
        retailData.forEach((subcategory, subcategoryIndex) => {
            if (subcategory.Products) {
                subcategory.Products.forEach((product, productIndex) => {
                    const productPrice = product.product_price;
                    const quantity = quantities[`${subcategoryIndex}-${productIndex}`] || 0;
                    total += productPrice * quantity;
                });
            }
        });
        return total.toFixed(2);
    };

    const calculateTotalDiscountValue = () => {
        let total = 0;
        retailData.forEach((subcategory, subcategoryIndex) => {
            if (subcategory.Products) {
                subcategory.Products.forEach((product, productIndex) => {
                    const productPrice = product.product_price;
                    const discountPrice = productPrice - (productPrice * subcategory.subcategory_discount) / 100;
                    const quantity = quantities[`${subcategoryIndex}-${productIndex}`] || 0;
                    total += discountPrice * quantity;
                });
            }
        });
        return total.toFixed(2);
    };

    const totalActualPrice = calculateTotalActualPrice();
    const totalDiscountValue = calculateTotalDiscountValue();
    const totalOfferPrice = (Number(totalActualPrice) - Number(totalDiscountValue)).toFixed(2);

    const filteredProducts = Object.entries(quantities)
        .filter(([_, quantity]) => quantity > 0)
        .map(([key, quantity]) => {
            const [subcategoryIndex, productIndex] = key.split("-");
            const product = retailData[subcategoryIndex]?.Products?.[productIndex];
            return { key, product, quantity };
        })
        .filter(({ product }) => product !== undefined); // Optional: Remove undefined products

    const handleCartClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    const handleProductClick = (product) => {
        setSelectedProduct(product);
        setShowProductDetailsModal(true); // Open product details modal
    };

    const handleCloseProductDetailsModal = () => {
        setSelectedProduct(null);
        setShowProductDetailsModal(false);
    };
    const handleModalQuantityChange = (key, value) => {
        const [subcategoryIndex, productIndex] = key.split("-");
        handleQuantityChange(subcategoryIndex, productIndex, value);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (

        <div>
            <div className="header-row ">
                <th colSpan="8">
                    <div className="header-content ">
                        <span>Products Count  <input type="text" value={filteredProducts.length} readOnly className="product-count-input" /></span>
                        <span>Actual Price <input type="text" value={`₹${totalActualPrice}`} readOnly className="actual-price-input" /></span>
                        <span>Discount Price <input type="text" value={`₹${totalDiscountValue}`} readOnly className="discount-price-input" /></span>
                        <span>
                            <FaShoppingCart className="cart-icon" onClick={handleCartClick} />
                            <Badge pill style={{ marginLeft: "-5px" }} className="badges-color">
                                {filteredProducts.length}
                            </Badge>
                        </span>
                    </div>
                </th>
            </div>

            <div className="table-container">
                <table className="custom-table">
                    <thead>
                        <tr className="header-row-head">
                            <th>Photo</th>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Content</th>
                            <th>Actual Price</th>
                            <th>Discount Price</th>
                            <th>Fill Quantity</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(retailData) && retailData.length > 0 ? (
                            retailData.map((subcategory, subcategoryIndex) => (
                                <React.Fragment key={subcategoryIndex}>
                                    <tr className="subcategory-header">
                                        <td colSpan="8">{subcategory.subcategory_name} {subcategory.subcategory_discount} %</td>
                                    </tr>
                                    {subcategory.Products && subcategory.Products.length > 0 ? (
                                        subcategory.Products.map((product, productIndex) => {
                                            const discountPrice = product.product_price - (product.product_price * subcategory.subcategory_discount) / 100;
                                            const productTotal = calculateProductTotal(subcategoryIndex, productIndex, discountPrice);
                                            return (
                                                <tr key={productIndex}>
                                                    <td>
                                                        <img
                                                            src={`https://api.srisrinivasacrackers.com/uploads/images/${product.image_url}`}
                                                            alt={product.product_name}
                                                            style={{ width: "50px", height: "50px" }}
                                                            onClick={() => handleProductClick(product)}
                                                        />
                                                    </td>
                                                    <td>{product.product_code}</td>
                                                    <td>{product.product_name}</td>
                                                    <td>{product.product_content}</td>
                                                    <td> <del style={{ color: "block", textDecoration: "line-through", textDecorationColor: "red" }}>
                                                        ₹{product.product_price}
                                                    </del>  </td>
                                                    <td>{discountPrice}</td>
                                                    <td>
                                                        <TextField
                                                            variant="outlined"
                                                            type="number"
                                                            placeholder="qty"
                                                            value={quantities[`${subcategoryIndex}-${productIndex}`] || ""}
                                                            onChange={(e) =>
                                                                handleQuantityChange(subcategoryIndex, productIndex, parseInt(e.target.value))
                                                            }
                                                            InputProps={{ inputProps: { min: 0 } }}
                                                            size="small"
                                                            style={{ width: "100px" }}
                                                        />
                                                    </td>
                                                    <td>{productTotal}</td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="8">No products available</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8">No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {/* Modal */}
                <Modal show={showModal} onHide={handleCloseModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Your Cart</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p><strong>Products Count:</strong> {filteredProducts.length}</p>
                        <p><strong>Total Actual Price:</strong> ₹{totalActualPrice}</p>
                        <p><strong>Total Offer Price:</strong> ₹{totalOfferPrice}</p>
                        <p><strong>Total Final Price:</strong> ₹{totalDiscountValue}</p>
                        <table className="table-responsive w-100">
                            <thead>
                                <tr className="table">
                                    {/* <th>S.No</th> */}
                                    <th className="t-photo">Photo</th>
                                    {/* <th>Code</th> */}
                                    <th>Name</th>
                                    {/* <th>Content</th> */}
                                    <th>Discount Price</th>
                                    <th>Fill Quantity</th>
                                    <th>Total</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProducts.length > 0 ? (
                                    filteredProducts.map(({ key, product, quantity }) => {
                                        const [subcategoryIndex, productIndex] = key.split("-");
                                        const subcategory = retailData[subcategoryIndex];
                                        const discount = subcategory?.subcategory_discount || 0;
                                        const discountPrice = product.product_price - (product.product_price * discount) / 100;
                                        const productTotal = calculateProductTotal(subcategoryIndex, productIndex, discountPrice);
                                        return (
                                            <tr key={key}>

                                                <td>
                                                    <img
                                                        src={`https://api.srisrinivasacrackers.com/uploads/images/${product.image_url}`}
                                                        alt={product.product_name}
                                                        style={{ width: "50px", height: "50px" }}
                                                    />
                                                </td>
                                                <td className="d-none">{product.product_code}</td>
                                                <td>{product.product_name}</td>
                                                <td className="d-none">{product.product_content}</td>
                                                <td>{discountPrice.toFixed(2)}</td>
                                                <td>
                                                    <TextField
                                                        variant="outlined"
                                                        type="number"
                                                        value={quantity}
                                                        onChange={(e) =>
                                                            handleModalQuantityChange(key, parseInt(e.target.value, 10))
                                                        }
                                                        InputProps={{ inputProps: { min: 0 } }}
                                                        size="small"
                                                        style={{ width: "100px" }}
                                                    />
                                                </td>
                                                <td>{productTotal}</td>
                                                <td>
                                                    <FaTrash
                                                        className="delete-icon"
                                                        onClick={() => handleDeleteProduct(key)}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="8">No products in the cart</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <form>
                            <TextField label="Name" name="name" value={formData.name} onChange={handleFormDataChange} fullWidth required InputLabelProps={{
                                style: { color: 'red' }
                            }}
                                margin="normal" />
                            <TextField label="Phone Number" name="phoneNumber" value={formData.phoneNumber} onChange={handleFormDataChange} required InputLabelProps={{
                                style: { color: 'red' }
                            }}
                                fullWidth margin="normal" />
                            <TextField label="Email" name="email" value={formData.email} onChange={handleFormDataChange} fullWidth
                                margin="normal" />
                            <TextField label="Address" name="address" value={formData.address} onChange={handleFormDataChange} fullWidth required InputLabelProps={{
                                style: { color: 'red' }
                            }}
                                margin="normal" />
                            <TextField label="State" name="state" value={formData.state} onChange={handleFormDataChange} fullWidth required InputLabelProps={{
                                style: { color: 'red' }
                            }}
                                margin="normal" />
                            <TextField label="Delivery City" name="deliveryCity" value={formData.deliveryCity} required InputLabelProps={{
                                style: { color: 'red' }
                            }}
                                onChange={handleFormDataChange} fullWidth margin="normal" />
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit} disabled={isCheckoutDisabled}  >
                            Checkout
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showProductDetailsModal} onHide={handleCloseProductDetailsModal} size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>Product Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedProduct && (
                            <div >
                                <div className="d-flex justify-content-center">
                                    <img
                                        src={`https://api.srisrinivasacrackers.com/uploads/images/${selectedProduct.image_url}`}
                                        alt={selectedProduct.product_name}
                                        style={{ width: "50%", height: "auto" }}
                                    />
                                </div>
                                <h4>{selectedProduct.product_name}</h4>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseProductDetailsModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                {showFireworks && (
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999 }}>
                        <Fireworks />
                    </div>
                )}
            </div >
        </div>
    );
};

export default WholesaleProduct;
