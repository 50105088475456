import logo from './logo.svg';
import './App.css';
import Navbars from './pages/Navbars';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import RetailPrice from './pages/RetailPrice';
import About from './pages/About';

import WholeSale from './pages/WholeSale';
import GiftBox from './pages/GiftBox';
import Contact from './pages/Contact';
import FamilyPack from './pages/FamilyPack';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbars />
        <Routes>
          <Route path='/' element={<RetailPrice />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/familypack' element={<FamilyPack />}></Route>
          <Route path='/retailprice' element={<RetailPrice />}></Route>
          <Route path='/wholesale' element={<WholeSale />}></Route>
          <Route path='/giftbox' element={<GiftBox />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
